import React from "react"
import Layout from "../components/layout"
import css from "classnames"
import CustomHelmet from "../components/CustomHelmet"
import { CallToAction } from "../components/CallToAction"
import HideDesktop from "../components/Shared/HideDesktop"
import HideMobile from "../components/Shared/HideMobile"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

// @ts-ignore
import styles from "./paso-a-paso.module.scss"

const PasoAPaso = () => {

  return (
    <StaticQuery
      query={graphql`
        query {
          step1: file(relativePath: { eq: "register.png"}) {
            childImageSharp {
              sizes(maxWidth: 600) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          step2: file(relativePath: { eq: "offer-blur.png"}) {
            childImageSharp {
              sizes(maxWidth: 600) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          step3: file(relativePath: { eq: "reserva.png"}) {
            childImageSharp {
              sizes(maxWidth: 600) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          step4: file(relativePath: { eq: "checklist.png"}) {
            childImageSharp {
              sizes(maxWidth: 600) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          step5: file(relativePath: { eq: "activities.jpg"}) {
            childImageSharp {
              sizes(maxWidth: 600) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
    `}
      render={data => {
        return (
          <Layout>
            <CustomHelmet
              title={"El proceso. Como planeamos tu viaje para estudiar ingles en Malta"}
              description={"Como planear tu curso de ingles. Que pasos seguir para la planeacion."}
              link={"/paso-a-paso/"}
            />
            <div className={styles.proceso}>
              <div className={styles.step}>
                <div className={css(styles.topSection, styles.textHalf)}>
                  <h1>¿Cómo funciona?</h1>
                  <div className={styles.headerExplain}>
                    El proceso de Desde Malta está diseñado para guiarte con la información más acorde a tu perfil y
                    necesidad de viaje, con la asesoría que necesites en el momento que lo necesites.
                  </div>

                  <h2 className={styles.explain}>Paso 1: Creación de tu perfil</h2>

                  <div>
                    En este primer paso, juntos crearemos tu perfil para entender qué tipo de estudiante eres y cuál es
                    el curso de inglés que verdaderamente necesitas.
                    Conoceremos cuánto tiempo deseas estar en la isla, qué presupuesto tienes y necesitas para tu viaje,
                    así estimaremos las opciones que más se adapten a ti.
                  </div>
                  <HideMobile><a href="https://mi.desdemalta.com/bienvenido/"><CallToAction>Comienza
                    aquí</CallToAction></a></HideMobile>
                </div>
                <div className={css(styles.imageHalf, styles.end)}>
                  <Img className={styles.img} sizes={data.step1.childImageSharp.sizes}
                       alt={"image ejemplo registracion"}/>
                </div>
              </div>
              <HideDesktop>
                <div className={styles.callToAction}><a href="https://mi.desdemalta.com/bienvenido/"><CallToAction>Comienza
                  aquí</CallToAction></a></div>
              </HideDesktop>
              <div className={styles.step}>
                <div className={css(styles.imageHalf)}>
                  <Img className={css(styles.img, styles.computer)} sizes={data.step2.childImageSharp.sizes}
                       alt={"image ejemplo offerta"}/>
                </div>
                <div className={styles.textHalf}>
                  <h2 className={styles.explain}>
                    Paso 2: Tus opciones
                  </h2>
                  <div>
                    Con base en la información previamente recogida en el formulario de creación de tu perfil,
                    ahora te daremos acceso a un enlace específicamente creado para ti, en el que podrás explorar
                    las primeras opciones ya ajustadas a tus necesidades para luego planear con más profundidad y
                    efectividad el resto de tu viaje.
                  </div>
                </div>
              </div>
              <div className={styles.step}>
                <div className={styles.textHalf}>
                  <h2 className={styles.explain}>
                    Paso 3: La reserva
                  </h2>
                  <div>
                    Una vez hayamos escogido la opción que nos guste para ti, continuaremos con la reserva de tu curso.
                    Recuerda que el pago siempre lo realizarás directamente a la escuela mientras nosotros estaremos
                    guiándote en este paso.
                  </div>
                </div>
                <div className={css(styles.imageHalf)}>
                  <Img className={styles.img} sizes={data.step3.childImageSharp.sizes} alt={"offerta escojida"}/>
                </div>
              </div>
              <div className={styles.step}>
                <div className={css(styles.imageHalf, styles.checklist)}>
                  <Img className={styles.img} sizes={data.step4.childImageSharp.sizes} alt={"checklist"}/>
                </div>
                <div className={styles.textHalf}>
                  <h2 className={styles.explain}>
                    Paso 4: Preparación de viaje
                  </h2>
                  <div>
                    Antes de viajar te ayudaremos a tener en orden todos los documentos requeridos por las autoridades
                    de control.
                    Puedes tener la tranquilidad de que no habrá ningún problema con tu ingreso al país, pues todos
                    nuestros estudiantes han tenido éxito en esta importante etapa.
                  </div>
                </div>
              </div>
              <div className={css(styles.step, styles.last)}>
                <div className={styles.textHalf}>
                  <h2 className={styles.explain}>
                    Soporte en la isla
                  </h2>
                  <div>
                    Una vez en la isla, seguirás recibiendo todo el soporte de nuestro equipo, además de estar invitado
                    a las actividades que organizamos para nuestros Desde Malta students.
                    En caso de que desees trabajar durante tu estadía, también te ayudaremos con la preparación de tu
                    hoja de vida.
                  </div>
                  <HideMobile><a href="https://mi.desdemalta.com/bienvenido/"><CallToAction>Comienza
                    aquí</CallToAction></a></HideMobile>
                </div>
                <div className={css(styles.imageHalf, styles.checklist)}>
                  <Img className={styles.img} sizes={data.step5.childImageSharp.sizes} alt={"actividades"}/>
                </div>
              </div>
              <HideDesktop>
                <div className={css(styles.callToAction, styles.last)}><a
                  href="https://mi.desdemalta.com/bienvenido/"><CallToAction>Comienza aquí</CallToAction></a></div>
              </HideDesktop>
            </div>

          </Layout>
        )
      }}
    />)
}

export default PasoAPaso